
import React, {useState, useEffect} from "react";

import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { colors } from  '../constants/colors';
import { fonts } from  '../constants/fonts';

import { useNavigation } from '@react-navigation/native';

import { createOrder, resetCart } from './../api/orders'
import { createTransaction } from './../api/transactions'

import './../assets/app.css'
const StripeCardForm = ({ cartData, toggleCartModal, togglePayCardModal, toggleCheckoutModal, toggleOrderReceivedModal, isPayCardModalVisible, subTotal }) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigation = useNavigation();
  const dispatch = useDispatch()

  var userData = useSelector(state => state.usersData.usersData);
  var branchData = useSelector(state => state.branchesData.selectedBranch);

  userData = userData[0]
  let customer = {}

  useEffect(() => {

    // Create PaymentIntent as soon as the page loads, only if subTotal is more than 0
    if (subTotal > 0) {

      axios.post("https://splinter.cloud.yuze.uk/create-payment-intent",
      {
        cart: cartData,
        branch: branchData
      })
      .then(res => {

        setClientSecret(res.data.clientSecret);
      })
      .catch(err => {
        setError(JSON.stringify(err))
      })

    }

  }, [isPayCardModalVisible]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    let email = userData != undefined ? String(userData.email) : String(cartData.customer.email)

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email
        }
      }
    });

    if (payload.error) {
      setError(`Payment failed:  ${payload.error.message}`);
      setProcessing(false);
    } else {
      // If no problems, create order, send email and then reset cart state

      // Assigns Stripe Id to Cart Before Create Order
      cartData.stripeId = payload.paymentIntent.id

      // Fix for delivry prices not adding
      if (typeof cartData.customer.location != "undefined") {
        subTotal = subTotal + cartData.customer.location[0].price
      }

      const payment = {
        amount: subTotal,
        name: "Online"
      }

      const transaction = await createTransaction(cartData, subTotal, null, null, payment, dispatch, true, false, branchData, userData)

      // Once everything is complete do all this. need order no.

      setError(null);
      setProcessing('');
      toggleCheckoutModal();
      toggleCartModal();
      togglePayCardModal();
      dispatch(resetCart())
      setDisabled(false)

      toggleOrderReceivedModal(transaction)
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        onChange={handleChange}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: colors.text,
              fontFamily: fonts.regular + ", sans-serif",
              '::placeholder': {
                color: colors.textSecondary,
              },
            },
            invalid: {
              color: colors.red,
            },
          },
      }} />

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}

      <button
         disabled={processing || disabled}
         id="submit"
       >
       <span id="button-text">
         {processing ? (
           <div className="spinner" id="spinner"></div>
         ) : (
           "Pay Now"
         )}
       </span>
     </button>

    </form>
  );
};

export default StripeCardForm;
