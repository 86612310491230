import React, { useState } from 'react';
import { View, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, FlatList } from 'react-native';

import { colors } from '../constants/colors';
import { fonts } from  '../constants/fonts';


import { useNavigation } from '@react-navigation/native'

import { connect, useDispatch } from 'react-redux';
import * as firebase from 'firebase';
import 'firebase/firestore';

import { selectBranch } from '../api/branches';

import moment from "moment";


import { setOrdersData, watchOrdersData, setOrdersDetailsData, watchOrdersDetailsData } from './../api/ordersData'
import { setUsersData, watchUsersData } from './../api/usersData'


import TimeAgo from 'react-native-timeago';

function FormatOrderNumber(num) {
    var r = "" + num;
    while (r.length < 3) {
        r = "0" + r;
    }
    return r;
}

function OrderItem({ order }) {

  const navigation = useNavigation()
  const [hover, setHover] = useState(false);

  if (order.timeCreated != null) {
    var timeCreated = moment(new Date(order.timeCreated.seconds * 1000)).format("DD/MM/YY - HH:mm")
    var timeCreatedAgo = <TimeAgo time={order.timeCreated.seconds * 1000} />
  }

  if (order.timeSlot != null) {
    var timeReady = moment(new Date(order.timeSlot.seconds * 1000)).format("DD/MM/YY - HH:mm")
    var timeReadyAgo = <TimeAgo time={order.timeSlot.seconds * 1000} />
  }

  return (
    <TouchableOpacity
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onPress={() => navigation.navigate('View', { id: order.id })}
      style={[styles.viewOrder, {flexDirection: "row"}]}>
      <View style={{flex: 3}}>
      <Text style={{ fontSize: 20, fontFamily: fonts.bold , color: colors.text}}>#{FormatOrderNumber(order.orderNo)}</Text>

      <View style={styles.viewOrderDetails}>
        {order.type == 0 ?
          <Text style={styles.viewOrderType}>In Branch</Text> :
        order.type == 1 ?
          <Text style={styles.viewOrderType}>Delivery</Text>  :
        order.type == 2 ?
          <Text style={styles.viewOrderType}>Collection</Text> : null
        }

        <Text style={styles.viewOrderType}>{'\u22C5'}</Text>

        <Text style={styles.viewOrderType}>{order.status == 1 ? "Awaiting Confirmation" : order.status == 2 ? "In Kitchen" : order.status == 3 ? "Ready/In Transit" : "Cancelled"}</Text>
      </View>

      <Text style={styles.viewOrderTime}>Ordered {timeCreatedAgo}</Text>
      <Text style={{fontSize: 16, color: colors.textSecondary, fontFamily: fonts.regular }}>£{(+order.totalPrice).toFixed(2)}</Text>
      </View>
      {global.windowWidth > 768 ?
        <View style={{ flex: 1 }}>
        <View style={hover ? styles.buttonHover : styles.button}>
          <Text style={styles.buttonText}>View Order</Text>
        </View>
        </View>
      : null}
    </TouchableOpacity>
  )
}

const mapStateToProps = (state) => {
  return {
    ordersData: state.ordersData,
    usersData: state.usersData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData()),
    watchOrdersData: () => dispatch(watchOrdersData())
  };
}


class OrdersScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: true,
      isLoading: true
    }
  }

  async componentDidMount() {

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ user: true })

        await this.props.watchUsersData()

        setTimeout(async () => {
          await this.props.watchOrdersData()
          this.setState({ isLoading: false })
        }, 800);
      } else {
        this.setState({ user: false, isLoading: false })
        this.props.navigation.navigate('Login')
      }
    })
  }


  renderSeparatorView = () => {
    return (
      <View style={{
          height: 1,
          width: "100%",
          backgroundColor: colors.border,
        }}
      />
    );
  };

  renderOrdersList = ( { item }) => {
    return (
      <OrderItem order={item} />
    )
  }

  render() {
    return (
      <View style={styles.main}>
      <View style={styles.header}>
        <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 30} ]}>
        <Text style={{color: colors.white, fontSize: 24, fontFamily: fonts.bold }} allowFontScaling>Orders</Text>
        </View>
      </View>

        {!this.state.user && this.state.isLoading == false ?
                <View style={styles.loadingContainer}>
                  <Text style={{color: colors.text, textAlign: "center", paddingVertical: 60, fontFamily: fonts.regular }}>Please log in to view your orders.</Text>
                </View>
        : this.props.ordersData.isLoading ?
          <View style={styles.loadingContainer}>
            <ActivityIndicator
              color={colors.primary}
            />
          </View>
        : this.props.ordersData.ordersData.length == 0 ?
        <View style={styles.loadingContainer}>
          <Text style={{colors: colors.text, textAlign: "center", paddingVertical: 60, fontFamily: fonts.regular }}>Your orders will show here once you've placed one with us.</Text>
        </View>
        : <View style={[styles.container]}>
        <FlatList
            data={this.props.ordersData.ordersData}
            keyExtractor={(order, index) => order.id.toString()}
            ItemSeparatorComponent={this.renderSeparatorView}
            ListFooterComponent={this.renderSeparatorView}
            renderItem={(item) => this.renderOrdersList(item)}
            initialNumToRender={8}
            maxToRenderPerBatch={8}
            windowSize={8}
          />
        </View>}
      </View>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersScreen);

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white,
    fontFamily: fonts.regular,
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: colors.text,
    padding: 20,
    fontFamily: fonts.regular
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  viewOrder: {
    flex: 1,
    paddingVertical: 40,
    backgroundColor: colors.white,
    paddingHorizontal: 30,
    borderColor: colors.border,
  },
  viewOrderType: {
    fontSize: 15,
    color: colors.textSecondary,
    fontFamily: fonts.regular,
    marginBottom : 10,
    justifyContent: "flex-start"
  },
  viewOrderTime: {
    fontSize: 13,
    paddingBottom: 2,
    color: colors.textSecondary,
    fontFamily: fonts.regular
  },
  viewOrderDetails: {
    flexDirection: "row"
  },
  button: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonHover: {
    height: 58,
    backgroundColor: "#EDC231",
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 16,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold
  },
});
