import * as firebase from "firebase";

import {useSelector} from 'react-redux';
import {connect, reduce, getState} from 'react-redux';

import { createOrder, resetCart, addTransactionNo, addOrderNo, addPayment, addOrderId } from './orders'

import { showMessage, hideMessage } from "react-native-flash-message";


async function createPayment(transactionId, payment, orderNo, isPaid) {

  // Get Timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  const data = {
    transaction: transactionId,
    amount: payment.amount,
    type: payment.name,
    timestamp: timestamp,
    branch: global.branchId,
    change: 0,
  }

  await global.backendFirebase.firestore()
    .collection("/transactions/" + global.companyId + "/transactionPayments")
    .add(data)
    .catch((error) => {
      showMessage({
            message: "Server Error",
            description: "Error adding Payment: " + error,
            type: "danger",
            icon: "danger",
            autoHide: false,
            hideOnPress: true,
          })
    });
}

async function createTransaction(cartData, totalPrice, printers, team, payment, dispatch, isPaid, isVoid, branch, userData) {
  // Get Timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  let transactionId = null
  let order = null
  let customer = {}

  // Get Last Transaction ID - Adds 1.
  await global.backendFirebase.firestore().collection("/transactions/" + global.companyId + "/transaction/")
    .where("branch", "==", global.branchId)
    .orderBy("timeCreated", "desc").limit(1)
    .get()
    .then(async function(querySnapshot) {

      //Assigns detail transactionNo
      let transactionNo = 100000

      if (branch.randomOrderNo != null | branch.randomOrderNo != undefined | branch.randomOrderNo != false) {
        // Random Order No
        transactionNo = Math.floor(Math.random() * 1000000) + 100000;
      } else {
        // Finds previous order no then works out what to do with it.
        querySnapshot.forEach(function(doc) {
          if (doc.data().transactionNo != null || doc.data().transactionNo == 999999) {
            if (doc.data().transactionNo != 999999) {
              transactionNo = doc.data().transactionNo + 1
            }
          }
        });
      }

      cartData.transactionNo = transactionNo

        // If user is logged in
        if (typeof userData == "undefined") {
          customer = {
              firstName: cartData.customer.firstName ? cartData.customer.firstName : null,
              secondName: cartData.customer.secondName ? cartData.customer.secondName : null,
              phone: cartData.customer.phone ? cartData.customer.phone : null,
              email: cartData.customer.email ? cartData.customer.email : null,
              postcode: cartData.customer.postcode ? cartData.customer.postcode: null,
              line1: cartData.customer.line1 ? cartData.customer.line1 : null,
              line2: cartData.customer.line2 ? cartData.customer.line2 : null,
              town: cartData.customer.town ? cartData.customer.town : null,
              county: cartData.customer.county ? cartData.customer.county : null,
              location: typeof cartData.customer.location != "undefined" ? cartData.customer.location[0] : null,
          }
        } else {
          customer = {
              firstName: userData.firstName ? userData.firstName : null,
              secondName: userData.secondName ? userData.secondName : null,
              phone: userData.phone ? userData.phone : null,
              email: userData.email ? userData.email : null,
              postcode: cartData.customer.postcode ? cartData.customer.postcode: null,
              line1: cartData.customer.line1 ? cartData.customer.line1 : null,
              line2: cartData.customer.line2 ? cartData.customer.line2 : null,
              town: cartData.customer.town ? cartData.customer.town : null,
              county: cartData.customer.county ? cartData.customer.county : null,
              location: typeof cartData.customer.location != "undefined" ? cartData.customer.location[0] : null,
              id: userData.id ? userData.id : null
          }

        }



      const data = {
        transactionNo: transactionNo,
        timeCreated: timestamp,
        totalPrice: totalPrice,
        isVoid: false,
        platform: "Online",
        branch: global.branchId,
        table: {
          id: cartData.table.id ? cartData.table.id : null,
          name: cartData.table.name ? cartData.table.name : null,
          covers: cartData.table.covers ? cartData.table.covers : null,
        },
        discount: {
          amount: cartData.discount.amount ? cartData.discount.amount : null,
          code: cartData.discount.code ? cartData.discount.code : null,
          type: cartData.discount.type ? cartData.discount.type : null,
        },
        customer: customer
      }

      await global.backendFirebase.firestore()
        .collection("/transactions/" + global.companyId + "/transaction")
        .add(data)
        .then(async function(docRef) {

          transactionId = docRef.id
          cartData.transactionId = transactionId


          // Add Order Items to the order.
          let colRef = global.backendFirebase.firestore()
            .collection("/transactions/" + global.companyId + "/transactionData")

          /// Batch Thing
          var batch = global.backendFirebase.firestore().batch();


          cartData.items.map(item => {

            var name = item.name
            var price = item.price
            var note = null
            var discountType = null
            var discountAmount = null

            if (item.variant) {
              name = item.variant.name + " " + item.name
              price = item.variant.price
            }

            if (item.note) {
              note = item.note
            }

            if (item.damount) {
              discountAmount = item.damount,
              discountType = item.dtype
            }

            batch.set(colRef.doc(), {
              transaction: docRef.id,
              branch: global.branchId,
              name: name,
              price: item.price,
              category: item.category,
              vid: item.variant != "" ? item.variant.id : item.vid ? item.vid :null,
              pid: item.pid,
              quantity: item.quantity,
              platform: "Online",
              note: note,
              timestamp: timestamp,
              discountAmount: discountAmount,
              discountType: discountType,
            })

          });

          batch.commit()
          .catch((error) => {
            console.log("Error Creating Transaction Batch: " + error)
          });

          // Creates Transaction Payment For the Transaction
          await createPayment(transactionId, payment)

          // Creates Order.
          order = await createOrder(cartData, totalPrice, userData, dispatch, transactionNo, isPaid, branch)
        })
        .catch((error) => {
          console.log("Error Creating Transaction: " + error)
        });

    })
    .catch(function(error) {
      console.log("Error Getting Last Transaction: " + error)
    });

    return order

}


export { createTransaction, createPayment }
