import * as React from 'react';
import { View, ScrollView, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, TextInput } from 'react-native';

import * as firebase from 'firebase';

import { colors } from './../constants/colors';
import { fonts } from './../constants/fonts';

import { useNavigation } from '@react-navigation/native'


class LoginScreen extends React.Component {

  state = {
    email: "",
    password: "",
    errorMessage: null,
    formLoading: false,
  }

  resetError = () => {
    return this.setState({errorMessage: null})
  }

  handleLogin = () => {
    this.setState({ formLoading: true })

    const {email, password} = this.state;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.navigation.navigate('Takeaway'),
        this.setState({ formLoading: false })
      })
      .catch(error => this.setState({errorMessage: error.message, formLoading: false }))
  }

  render() {
    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={styles.container}>
          <Text style={{color: colors.white, fontFamily: fonts.bold, fontSize: 24}}>Login Account</Text>
          </View>
        </View>

        <ScrollView style={[styles.container, { paddingTop: 30}]}>
        {this.state.errorMessage ?
          <Text style={styles.errorMessage} adjustsFontSizeToFit allowFontScaling><Text style={{fontFamily: fonts.bold}}>Error</Text> {this.state.errorMessage}</Text>
        : null}

          <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Email<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            onChangeText={email => (this.setState({ email }, this.resetError()))}
            value={this.state.email}
            ref={(input) => { this.input1 = input; }}
            onSubmitEditing={() => { this.input2.focus(); }}
          ></TextInput>

          <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Password<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            secureTextEntry
            onChangeText={password => (this.setState({ password }, this.resetError()))}
            value={this.state.password}
            ref={(input) => { this.input2 = input; }}
            ></TextInput>

            <TouchableOpacity style={styles.button} onPress={this.handleLogin}>
              {this.state.formLoading ?
              <ActivityIndicator size="small" color={colors.text} />
              : <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Log In</Text> }
            </TouchableOpacity>

            <View style={{ flexDirection: "row", justifyContent: "center"}}>
              <Text style={styles.footerText} adjustsFontSizeToFit allowFontScaling>Don't have an account?</Text>
              <TouchableOpacity
              style={{ paddingTop: 20}}
              onPress={() => this.props.navigation.navigate("Register")}
              >
              <Text style={styles.footerText, { color: colors.textSecondary, textAlign: "center"}} adjustsFontSizeToFit allowFontScaling> Register here.</Text>
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: "row", justifyContent: "center"}}>
              <Text style={styles.footerText} adjustsFontSizeToFit allowFontScaling>Forgot your password?</Text>
              <TouchableOpacity
              style={{ paddingTop: 20}}
              onPress={() => this.props.navigation.navigate("ResetPassword")}
              >
              <Text style={styles.footerText, { color: colors.textSecondary, textAlign: "center"}} adjustsFontSizeToFit allowFontScaling> Reset here.</Text>
              </TouchableOpacity>
            </View>
        </ScrollView>

      </View>
    )
  }
}

export default LoginScreen;

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 500,
    paddingHorizontal: 30
  },
  text: {
    color: colors.text,
    padding: 20,
    fontFamily: fonts.regular,
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  heading: {
    fontSize: 10,
    letterSpacing: 1,
    fontFamily: fonts.bold,
    color: colors.text,
    textTransform: "uppercase"
  },
  text: {
    alignItems: 'center',
    textAlign: "center",
    fontFamily: fonts.regular,
  },
  button: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 16,
    textAlign: "center",
    color: colors.primary
  },
  input: {
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 25,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
    fontFamily: fonts.regular
  },
  inputRequired: {
    color: colors.red,
    fontFamily: fonts.bold,
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 30,
    fontFamily: fonts.regular
  },
  footerText: {
    paddingTop: 20,
    color: colors.text,
    fontFamily: fonts.regular
  }
});
