import React, { useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity
} from "react-native";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';

class ItemModifierPicker extends React.Component {

  constructor(props) {
    super(props);
  }

  renderItemModifierPicker = (modifiers, modifierItems, selectedModifiers, toggleSelectedModifier) => {

    return modifierItems.map((modifier, index) => {

      let selected = selectedModifiers.filter(m => m.id === modifier.id)

      return (
        <TouchableOpacity
          onPress={() => toggleSelectedModifier(modifier)}
          style={selected.length > 0 ? styles.modifierSelected : styles.modifier}
          disabled={modifier.isAvailable != undefined && modifier.isAvailable == false ? true : false}
          key={index.toString()}>
          <Text style={styles.name}>{modifier.name}</Text>

          {modifier.isAvailable != undefined && modifier.isAvailable == false ?
            <Text style={styles.unavailableLabel} adjustsFontSizeToFit allowFontScaling numOfLines={1}>Unavailable</Text>
          : null }

          <Text style={styles.price}>{"£" + (+modifier.price).toFixed(2)}</Text>
        </TouchableOpacity>
      )
    })
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderItemModifierPicker(this.props.modifiers, this.props.modifierItems, this.props.selectedModifiers, this.props.toggleSelectedModifier)}
      </View>
    )
  }
}

export default ItemModifierPicker;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
  },
  modifier: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: colors.border,
    padding: 20,
    borderRadius: 4,
    marginBottom: 15,
    fontFamily: fonts.regular,
  },
  modifierSelected: {
    flexDirection: "row",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.border,
    backgroundColor: 'rgba(150,150,150, 0.1)',
    padding: 20,
    marginBottom: 15,
  },
  name: {
    flex: 1,
    color: colors.text,
    fontSize: 13,
    fontFamily: fonts.regular,
  },
  price: {
    color: colors.text,
    fontSize: 13,
    fontFamily: fonts.regular,
  },
  unavailableLabel: {
    backgroundColor: colors.border,
    color: colors.textSecondary,
    fontSize: 12,
    right: 70,
    paddingVertical: 7,
    paddingHorizontal: 12,
    marginTop: -7,
    position: "absolute",
    fontFamily: fonts.regular,
  },
});
