import * as React from 'react';
import { View, ScrollView, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, TextInput } from 'react-native';

import * as firebase from 'firebase';

import { colors } from './../constants/colors';
import { fonts } from './../constants/fonts';

import { useNavigation } from '@react-navigation/native'


class PasswordResetScreen extends React.Component {

  state = {
    email: "",
    errorMessage: null,
    sucessMessage: null,
    formLoading: false,
  }

  resetError = () => {
    return this.setState({errorMessage: null})
  }

  handleLogin = () => {
    this.setState({ formLoading: true })

    const { email } = this.state;

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({ formLoading: false, successMessage: "A reset link has been sent to your email if the email exists. Please check your spam." })

        setTimeout(
          () => { this.props.navigation.goBack() },
          1200
        )
      })
      .catch(error => this.setState({errorMessage: error.message, formLoading: false }))
  }

  render() {
    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={styles.container}>
          <Text style={{color: colors.white, fontFamily: fonts.bold, fontSize: 24}}>Reset Password</Text>
          </View>
        </View>

        <ScrollView style={[styles.container, { paddingTop: 30}]}>
        {this.state.errorMessage ?
          <Text style={styles.errorMessage}><Text style={{fontWeight: "600"}}>Error</Text> {this.state.errorMessage}</Text>
        : null}

        {this.state.successMessage ?
          <Text style={styles.successMessage}><Text style={{fontWeight: "600"}}>Success</Text> {this.state.successMessage}</Text>
        : null}

          <Text style={styles.heading}>Email<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            onChangeText={email => (this.setState({ email }, this.resetError()))}
            value={this.state.email}
            ref={(input) => { this.input1 = input; }}
            onSubmitEditing={() => { this.input2.focus(); }}
          ></TextInput>

            <TouchableOpacity style={styles.button} onPress={this.handleLogin}>
              {this.state.formLoading ?
              <ActivityIndicator size="small" color={colors.text} />
              : <Text style={styles.buttonText}>Reset Password</Text> }
            </TouchableOpacity>

            <View style={{ flexDirection: "row", justifyContent: "center"}}>
              <Text style={styles.footerText}>Know your password?</Text>
              <TouchableOpacity
              style={{ paddingTop: 20}}
              onPress={() => this.props.navigation.navigate("Login")}
              >
              <Text style={styles.footerText, { color: colors.textSecondary, textAlign: "center"}}> Login here.</Text>
              </TouchableOpacity>
            </View>
        </ScrollView>

      </View>
    )
  }
}

export default PasswordResetScreen;

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 500,
    paddingHorizontal: 30,
  },
  text: {
    color: colors.text,
    padding: 20,
    fontFamily: fonts.regular
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  heading: {
    fontSize: 10,
    letterSpacing: 0.5,
    color: colors.text,
    fontFamily: fonts.bold,
    textTransform: "uppercase"
  },
  button: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold
  },
  input: {
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 25,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
    fontFamily: fonts.regular
  },
  inputRequired: {
    color: colors.red,
    fontFamily: fonts.bold
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 30,
    fontFamily: fonts.regular
  },
  checkboxSection: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: -5,
    flexDirection: "row",
    fontFamily: fonts.regular
  },
  checkbox: {
    margin: 8,
  },
  footerText: {
    paddingTop: 20,
    color: colors.text,
    fontFamily: fonts.regular
  },
  successMessage: {
    color: colors.white,
    fontFamily: fonts.regular,
    padding: 10,
    backgroundColor: colors.green,
    marginBottom: 30,
  },
});
