// Main Theme Items


// Main Fonts
// Dont forget to update app.js with the fonts you need.
const fonts = {
  extraBold: 'Inter-Bold',
  bold: 'Inter-SemiBold',
  regular: 'Inter-Medium',
};

const colors = {
  primary: "#292929",
  secondary: "#ffcf19",
  text: "#292929",
  textSecondary: "#535560",
  border: "#DEDEDE",
  blue: "#3498DB",
  blueSecondary: "#2F87C2",
  red: "#E74C3C",
  redSecondary: "#CF4536",
  green: "#2ECC71",
  greenSecondary: "#29B362",
  yellow: "#F39C12",
  yellowSecondary: "#D98B0F",
  darkGrey: "#24252A",
  white: "#FFFFFF",
  whiteSecondary: "#C1C1C1",
};

const theme = {
  button: {
    height: 60,
    borderRadius: 5,
    paddingVertical: 20,
    paddingHorizontal: 30,
    backgroundColor: colors.secondary,
    textColor: colors.primary
  },
  buttonDisabled: {
    opacity: 0.8,
  },
  buttonText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold,
  }
}

export { fonts, colors, theme }
