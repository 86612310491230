
import React, { useState, useEffect } from "react";

import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { useNavigation } from '@react-navigation/native';

import { createOrder, resetCart } from './../api/orders'
import { createTransaction } from './../api/transactions'

import './../assets/app.css'


const StripeRequestButton = ({ subTotal, toggleCartModal, toggleCheckoutModal, toggleOrderReceivedModal }) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const navigation = useNavigation();
  const dispatch = useDispatch()

  var userData = useSelector(state => state.usersData.usersData);
  var branchData = useSelector(state => state.branchesData.selectedBranch);
  var cartData = useSelector(state => state.cartData)

  userData = userData[0]
  let customer = {}


  useEffect(() => {

    const createIntent = async () => {
      await axios.post("https://splinter.cloud.yuze.uk/create-payment-intent",
      {
        cart: cartData,
        branch: branchData
      })
      .then(res => {
        setClientSecret(res.data.clientSecret);
      })
      .catch(err => {
        setError(JSON.stringify(err))
      })
    }

    // Create PaymentIntent as soon as the page loads, only if subTotal is more than 0
    if (subTotal > 0) {

      // Correct subtotal for Stripe
      let stripeSubTotal = subTotal.toFixed(2) * 100
      stripeSubTotal = Math.round(stripeSubTotal)

      if (stripe && cartData && !paymentRequest) {

        const pr = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: 'Order from ' + branchData.name,
            amount: stripeSubTotal,
          },
          requestPayerName: false,
          requestPayerEmail: false,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        });

      }

      createIntent()
    }



  }, [stripe, cartData, paymentRequest]);

  useEffect(() => {

      // Updates Payment Request if any updates to the cart data
          // Correct subtotal for Stripe
          let stripeSubTotal = subTotal.toFixed(2) * 100
          stripeSubTotal = Math.round(stripeSubTotal)

          setIsReloading(true)
        
          if (paymentRequest && cartData) {
    
            paymentRequest.update({
              total: {
                label: 'Order from ' + branchData.name,
                amount: stripeSubTotal,
              },
            });

          }

          setIsReloading(false)

  }, [paymentRequest, cartData]);


  const processOrder = async (paymentIntent) => {

    if (!isConfirmingPayment) {
      
      // If no problems, create order, send email and then reset cart state

      // Assigns Stripe Id to Cart Before Create Order
      cartData.stripeId = paymentIntent

      // Fix for delivry prices not adding
      if (typeof cartData.customer.location != "undefined") {
        subTotal = subTotal + cartData.customer.location[0].price
      }

      const payment = {
        amount: subTotal,
        name: "Online"
      }

      const transaction = await createTransaction(cartData, subTotal, null, null, payment, null, true, false, branchData, userData)

      // Once everything is complete do all this. need order no.

      setError(null);
      setProcessing('');
      toggleCheckoutModal();
      toggleCartModal();
      dispatch(resetCart())
      setDisabled(false)

      toggleOrderReceivedModal(transaction)
    }

  }


  const handlePaymentSubmit = async () => {

  if (paymentRequest && !isReloading) {

    if (clientSecret != '') {

    paymentRequest.on('paymentmethod', async (ev) => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    setIsConfirmingPayment(true);

    const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
      clientSecret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete('fail');
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          setError(error)
        } else {
          // The payment has succeeded.
          processOrder(paymentIntent.id)
        }
      } else {
        // The payment has succeeded.
        processOrder(paymentIntent.id)
      }

      setIsConfirmingPayment(false);
    }
  });

    }

  }

}



const options = {
  paymentRequest,
  style: {
    paymentRequestButton: {
      theme: 'dark',
      height: '60px',
    },
  }
}

  return (
    <div className="payment-request-button">

      {paymentRequest ? 
        <PaymentRequestButtonElement 
          options={options}
          onClick={handlePaymentSubmit}
          disabled={isConfirmingPayment}
        />
      : null }

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}

      <p className="or">Or</p>
    </div>
    )

};

export default StripeRequestButton;
