// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyDyXVONAuW8CdcNoyvYHQjyLRiOzTHkvXs",
  authDomain: "fyre-babuls.firebaseapp.com",
  projectId: "fyre-babuls",
  storageBucket: "fyre-babuls.appspot.com",
  messagingSenderId: "1037394886203",
  appId: "1:1037394886203:web:a00b7849883eb7c662e61e",
  measurementId: "G-JKEL6NQYRY"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
