import * as React from 'react';
import { View, ScrollView, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, TextInput } from 'react-native';

import * as firebase from 'firebase';

import { connect, useDispatch } from 'react-redux';
import { setUsersData, watchUsersData } from './../api/usersData';

import { colors } from './../constants/colors';
import { fonts } from './../constants/fonts'


const mapStateToProps = (state) => {
  return {
    usersData: state.usersData.usersData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData())
  };
}

class ProfileScreen extends React.Component {


  constructor(props) {
    super(props);

    this.props.watchUsersData()

    this.state = {
      password: "",
      confirmPassword: "",
      firstName: this.props.usersData[0].firstName,
      secondName: this.props.usersData[0].secondName,
      phone: this.props.usersData[0].phone,
      errorMessage: null,
      successMessage: null,
      formLoading: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.usersData !== this.props.usersData) {
      this.setState({
        firstName: nextProps.usersData[0].firstName,
        secondName: nextProps.usersData[0].secondName,
        phone: nextProps.usersData[0].phone,
      });
    }
  }


  resetError = () => {
    return this.setState({errorMessage: null, successMessage: null})
  }


  handleUpdate = () => {

    this.setState({ formLoading: true })

    const emailReg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const phoneReg = new RegExp(/^((\+44)|(0)) ?\d{4} ?\d{6}$/)

    if (this.state.firstName == "" ||
        this.state.secondName == "" ||
        this.state.phone == "") {
          this.setState({errorMessage: "Please fill out the required fields"})
    } else {


      if (this.state.password != "" && this.state.confirmPassword != "" && this.state.password != this.state.confirmPassword) {
        this.setState({errorMessage: "Passwords don't match"})
      }

      else if (phoneReg.test(this.state.phone) === false) {
        this.setState({errorMessage: "Please enter a valid phone number"})
      } else {
            // Fnally gets to Update User Information

            // Get Timestamp
            const timeUpdated = firebase.firestore.FieldValue.serverTimestamp();

            global.backendFirebase.firestore()
              .collection("/customers/" + global.companyId + "/customer")
                .doc(this.props.usersData[0].id).update({
                  firstName: this.state.firstName,
                  secondName: this.state.secondName,
                  phone: this.state.phone,
                  timeUpdated: timeUpdated
                })
                .then(() => {
                  this.setState({successMessage: "Account updated!"})
                  this.setState({ formLoading: false })

                  setTimeout(
                    () => { this.props.navigation.goBack() },
                    900
                  )
                })
                .catch((error) => {
                  this.setState({errorMessage: error.name})
                  this.setState({ formLoading: false })
                })
      }
    }
  }
  render() {
    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={styles.container}>
          <Text style={{color: colors.white, fontFamily: fonts.bold, fontSize: 24}}>Update Profile</Text>
          </View>
        </View>

        <ScrollView style={[styles.container, { paddingTop: 30}]} avoidKeyboard={true}>

          {this.state.errorMessage ?
            <Text style={styles.errorMessage}><Text style={{ fontFamily: fonts.bold }}>Error</Text> {this.state.errorMessage}</Text>
          : null}


          {this.state.successMessage ?
            <Text style={styles.successMessage}><Text style={{ fontFamily: fonts.bold }}>Success</Text> {this.state.successMessage}</Text>
          : null}
          <Text style={styles.heading}>First Name<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            autoCompleteType="name"
            onChangeText={firstName => (this.setState({ firstName }), this.resetError())}
            value={this.state.firstName}
            ref={(input) => { this.input1 = input; }}
            onSubmitEditing={() => { this.input2.focus(); }}
          ></TextInput>


          <Text style={styles.heading}>Last Name<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            autoCompleteType="name"
            onChangeText={secondName => (this.setState({ secondName }), this.resetError())}
            value={this.state.secondName}
            ref={(input) => { this.input2 = input; }}
            onSubmitEditing={() => { this.input3.focus(); }}
          ></TextInput>

          <Text style={styles.heading}>Mobile Number<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            autoCompleteType="tel"
            onChangeText={phone => (this.setState({ phone }), this.resetError())}
            value={this.state.phone}
            keyboardType="phone-pad"
            ref={(input) => { this.input4 = input; }}
            onSubmitEditing={() => { this.input5.focus(); }}
          ></TextInput>

            <TouchableOpacity style={styles.button} onPress={this.handleUpdate}>
              {this.state.formLoading ?
              <ActivityIndicator size="small" color={colors.text} />
              : <Text style={styles.buttonText}>Update Profile</Text> }
            </TouchableOpacity>
        </ScrollView>

      </View>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 500,
    paddingHorizontal: 30,
  },
  text: {
    color: colors.text,
    padding: 20,
    fontFamily: fonts.regular
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  heading: {
    fontSize: 10,
    letterSpacing: 1,
    fontFamily: fonts.bold,
    color: colors.text,
    textTransform: "uppercase"
  },
  button: {
    backgroundColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  input: {
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 25,
    fontFamily: fonts.regular,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
  inputRequired: {
    color: colors.red,
    fontFamily: fonts.bold
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 30,
  },
  successMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.green,
    marginBottom: 30,
  },
  button: {
        height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 16,
    textAlign: "center",
    color: colors.primary
  },
});
