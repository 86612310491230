import * as React from 'react';
import { View, ScrollView, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, TextInput } from 'react-native';

import * as firebase from 'firebase';

import { connect, useDispatch } from 'react-redux';
import { setUsersData, watchUsersData } from './../api/usersData';

import { colors } from './../constants/colors';
import { fonts } from './../constants/fonts'


const mapStateToProps = (state) => {
  return {
    usersData: state.usersData.usersData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData())
  };
}

class AddressesScreen extends React.Component {


  constructor(props) {
    super(props);

    this.props.watchUsersData()

    this.state = {
      line1: this.props.usersData[0].address.line1,
      line2: this.props.usersData[0].address.line2,
      town: this.props.usersData[0].address.town,
      postcode: this.props.usersData[0].address.postcode,
      errorMessage: null,
      successMessage: null,
      formLoading: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.usersData !== this.props.usersData) {
      this.setState({
        line1: nextProps.usersData[0].address.line1,
        line2: nextProps.usersData[0].address.line2,
        town: nextProps.usersData[0].address.town,
        postCode: nextProps.usersData[0].address.postcode,
      });
    }
  }


  resetError = () => {
    return this.setState({errorMessage: null, successMessage: null})
  }


  handleUpdate = () => {

    this.setState({ formLoading: true })

    const postcodeReg = new RegExp(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i)
    if (this.state.line1 == "" ||
        this.state.town == "" ||
        this.state.postcode == "") {
          this.setState({errorMessage: "Please fill out the required fields"})
    } else {

      if (postcodeReg.test(this.state.postcode) === false) {
        this.setState({errorMessage: "Please enter a valid postcode"})
      } else {

            // Get Timestamp
            const timeUpdated = firebase.firestore.FieldValue.serverTimestamp();

            global.backendFirebase.firestore()
              .collection("/customers/" + global.companyId + "/customer")
                .doc(this.props.usersData[0].id).update({
                  'address.line1': this.state.line1,
                  'address.line2': this.state.line2,
                  'address.town': this.state.town,
                  'address.postcode': this.state.postcode,
                  'timeUpdated': timeUpdated
                })
                .then(() => {
                  this.setState({successMessage: "Address updated!"})
                  this.setState({ formLoading: false })

                  setTimeout(
                    () => { this.props.navigation.goBack() },
                    900
                  )
                })
                .catch((error) => {
                  this.setState({errorMessage: error.name})
                  this.setState({ formLoading: false })
                })
      }
    }
  }
  render() {
    return (
      <View style={styles.main}>

        <View style={styles.header}>
          <View style={styles.container}>
          <Text style={{color: colors.white, fontFamily: fonts.bold, fontSize: 24}}>Update Delivery Address</Text>
          </View>
        </View>

        <ScrollView style={[styles.container, { paddingTop: 30}]} avoidKeyboard={true}>

          {this.state.errorMessage ?
            <Text style={styles.errorMessage}><Text style={{fontWeight: "600"}}>Error</Text> {this.state.errorMessage}</Text>
          : null}


          {this.state.successMessage ?
            <Text style={styles.successMessage}><Text style={{fontWeight: "600"}}>Success</Text> {this.state.successMessage}</Text>
          : null}
          <Text style={styles.heading}>Line 1<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            autoCompleteType="street-address"
            onChangeText={line1 => (this.setState({ line1 }), this.resetError())}
            value={this.state.line1}
            ref={(input) => { this.input1 = input; }}
            onSubmitEditing={() => { this.input2.focus(); }}
          ></TextInput>


          <Text style={styles.heading}>Line 2</Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            onChangeText={line2 => (this.setState({ line2 }), this.resetError())}
            value={this.state.line2}
            ref={(input) => { this.input2 = input; }}
            onSubmitEditing={() => { this.input3.focus(); }}
          ></TextInput>

          <Text style={styles.heading}>Town<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            onChangeText={town => (this.setState({ town }), this.resetError())}
            value={this.state.town}
            ref={(input) => { this.input3 = input; }}
            onSubmitEditing={() => { this.input4.focus(); }}
          ></TextInput>

          <Text style={styles.heading}>Postcode<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="characters"
            autoCompleteType="postal-code"
            onChangeText={postcode => (this.setState({ postcode }), this.resetError())}
            value={this.state.postcode}
            ref={(input) => { this.input4 = input; }}
          ></TextInput>

          <TouchableOpacity style={styles.button} onPress={this.handleUpdate}>
            {this.state.formLoading ?
            <ActivityIndicator size="small" color={colors.text} />
            : <Text style={styles.buttonText}>Update Address</Text> }
          </TouchableOpacity>
        </ScrollView>

      </View>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesScreen);

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 500,
    paddingHorizontal: 30,
  },
  text: {
    color: colors.text,
    padding: 20,
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  heading: {
    fontSize: 10,
    letterSpacing: 1,
    fontFamily: fonts.bold,
    color: colors.text,
    textTransform: "uppercase"
  },
  button: {
    backgroundColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  input: {
    fontSize: 15,
    paddingTop: 10,
    fontFamily: fonts.regular,
    paddingBottom: 10,
    marginBottom: 25,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
  inputRequired: {
    color: colors.red,
    fontWeight: "800",
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 30,
  },
  successMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.green,
    marginBottom: 30,
  },
  button: {
        height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: colors.primary
  },
});
