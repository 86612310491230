import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    I18nManager,
    Animated,
    Platform
} from "react-native";

import { colors, theme } from '../constants/theme';
import Icon from 'react-native-vector-icons/Feather';

import { RectButton } from 'react-native-gesture-handler';
import Swipeable from 'react-native-gesture-handler/Swipeable';


export default function CartItem({item, index, onPress, removeItem}) {

  const test = () => {
    return true
  }
  const renderRightAction = (text, color, x, progress, action) => {
    const trans = progress.interpolate({
      inputRange: [0, 1],
      outputRange: [x, 0],
    });
    return (
      <Animated.View style={{ flex: 1, transform: [{ translateX: trans }] }}>
        <RectButton
          style={[styles.rightButton, { backgroundColor: color }]}
          onPress={action}>
          <Text style={styles.rightButtonText}>{text}</Text>
        </RectButton>
      </Animated.View>
    );
  };

  const renderRightActions = progress => (
    <View style={{ width: 100, flexDirection: I18nManager.isRTL? 'row-reverse' : 'row' }}>
      {renderRightAction('Delete', colors.red, 120, progress, removeItem)}
    </View>
  );

  return (
    <Swipeable
      key={index}
      friction={1}
      rightThreshold={40}
      renderRightActions={renderRightActions}
      overshootRight={false}>
      <TouchableOpacity
        style={{ paddingTop: 17, paddingBottom: 13 }}>
        <View style={{ flexDirection: "row"}}>
        <View style={{ flex: 2, flexDirection: "row"}}>
          <Text style={{ fontSize: 11, fontWeight: "700", color: colors.text, backgroundColor: 'rgba(100, 100, 100, 0.1)', paddingVertical: 4 , paddingHorizontal: 8, marginTop: -2, marginRight: 10}}>{item.quantity}</Text>
          <Text style={{fontSize: 13, fontWeight: "600"}} numberOfLines={1}>{item.variant ? item.variant.name + " " : null}{item.name}</Text>
        </View>

        {Platform.OS == "web" ?
        <View style={{flex: 0.5, alignItems: "right"}}>
          <Icon name="trash-2" size={18} color={'rgba(100, 100, 100, 0.6)'} style={{marginTop: -2, textAlign: "right"}} onPress={removeItem}/>
        </View>
        : null }

        <Text style={{ fontSize: 13, flex: 0.5, textAlign: "right"}}>
          {item.variant ?
            "£" + (+item.variant.price * item.quantity).toFixed(2)
          : "£" + (+item.price * item.quantity).toFixed(2) }
        </Text>
        </View>

        {item.modifiers.length > 0 ?
          item.modifiers.map((m, i) => {
            return (
              <View key={i} style={styles.cartItemModifiers}>
                <Text style={styles.cartItemModifiersText}>{m.name}</Text>
                <Text style={styles.cartItemModifiersTextRight}>{"£" + (+m.price).toFixed(2)}</Text>
              </View>
            )
          })
        : null}
      </TouchableOpacity>
    </Swipeable>
  )
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
  },
  rightButtonText: {
    ...theme.p,
    fontSize: 12,
    color: colors.white,
  },
  cartItemQuantity: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  cartItemQuantityButton: {
    paddingVertical: 7,
    paddingHorizontal: 17,
    borderWidth: 1,
    borderColor: colors.border,
    marginLeft: 10,
    marginTop: -10,
    borderRadius: 50,
  },
  cartItemQuantityText: {
    ...theme.p,
    color: colors.white,
    fontSize: 18,
  },
  cartItemModifiers: {
    flex: 1,
    flexDirection: "row",
    flexWrap: 'wrap',
    paddingTop: 4,
    paddingLeft: 32,
  },
  cartItemModifiersText: {
    ...theme.p,
    flex: 3.5,
    fontSize: 13,
    color: colors.textSecondary,
    textAlign: "left",
  },
  cartItemModifiersTextRight: {
    ...theme.p,
    flex: 1,
    fontSize: 13,
    color: colors.textSecondary,
    textAlign: 'right',
  },
  rightButton: {
    marginLeft: 15,
    paddingVertical: 17,
    paddingHorizontal: 15,
  },
});
