import React from 'react';

import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';

import { useWindowDimensions, Text } from 'react-native';

import * as firebase from 'firebase';
import 'firebase/firestore';

import { HomeStack } from './HomeStack'
import { OrdersStack } from './OrdersStack'
import { AuthStack } from './AuthStack'

import Account from './../components/Account'

//  Import Custom Drawer

import DrawerContent from '../containers/DrawerContent';

const Drawer = createDrawerNavigator();

export function MainDrawer(props) {

  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 1024;

  var user = null

  firebase.auth().onAuthStateChanged(function(user) {
    var user = user
  })

  // Essentially a site map.

  const config = {
    screens: {
      Orders: {
        screens: {
          Orders: 'orders',
          View: {
            path: 'orders/:id',
            parse: {
              id: (id) => `View-${id}`,
            },
            stringify: {
              id: (id) => id.replace(/^View-/, ''),
            },
          }
        }
      },
      Home: {
        screens: {
          Takeaway: 'takeaway'
        }
      },
      Account: {
        screens: {
          Account: 'account',
          Profile: 'account/profile',
          Addresses: 'account/addresses',
          Login: 'account/login',
          Register: 'account/register',
          ResetPassword: 'account/reset',
          Terms: 'account/terms',
          Privacy: 'account/privacy'
        }
      }
    },
  };

  const linking = {
    prefixes: ['http://localhost:19006/', 'babuls://'],
    config,
  };


  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Drawer.Navigator
        drawerType="slide"
        initialRouteName="order"
        drawerType={isLargeScreen ? 'front' : 'back'}
        drawerContent={props => <DrawerContent {...props} />}
      >
      <Drawer.Screen
        name="Home"
        component={HomeStack}
        options={{
          title: "Order Takeaway", //Set Header Title
          headerTitle: "Order Takeaway"
        }}
      />

      <Drawer.Screen
        name="Orders"
        component={OrdersStack}
        options={{
          title: "Orders", //Set Header Title
          headerTitle: "Orders"
        }}
      />

      <Drawer.Screen
        name="Account"
        component={Account}
        options={{
          title: "Account", //Set Header Title
          headerTitle: "Account"
        }}
      />
      </Drawer.Navigator>
    </NavigationContainer>
  )
};
