import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';


import Modal from 'modal-enhanced-react-native-web';

import moment from "moment";

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'

import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';

function AddTimeButton({ toggleTimeModal, selectedTime, selectedDate, cartData }) {

  const dispatch = useDispatch()
  const navigation = useNavigation()

  let combined = null

  // If the time is ASAP, assign Date as ASAP for order.
  if (selectedTime == "ASAP") {
    combined = "ASAP"
  } else {
    const date = selectedDate + " " + selectedTime
    combined = moment(date, 'dddd Do MMMM HH:mm').toDate()
  }

  // Add to redux
  const addTimeSlot = (combined) => {
    return {
      type: "ADD_ORDER_TIME",
      payload: combined,
    }
  }

  const onPress = () => {
    return (
      dispatch(addTimeSlot(combined)),
      toggleTimeModal()
    )
  }

  return (
    <TouchableOpacity
    disabled={selectedTime && selectedDate ? false : true }
    onPress={onPress}
    style={selectedTime && selectedDate ? styles.orderButton : styles.orderButtonDisabled}
    >
      <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Change {cartData.type == 2 ? "Collection" : cartData.type == 1 ? "Delivery" : null} Time</Text>
    </TouchableOpacity>
  )
}
function getDays(maxPreOrderDays, hoursData) {

  // This function works out which dates the branch is open for the customer and how long in advance the customer can preorder, if the option is granted in Firestore.
  let days = [];
  let daysRequired = maxPreOrderDays - 1

  // Filters days for specific branch
  hoursData = hoursData.filter(h => h.branch == global.branchId)

  // Looks next dates with preorder.
  for (let i = 0; i <= daysRequired; i++) {

    const date = moment().add(i, 'days')
    const day = date.format("d")

    hoursData.map(h => {
      // If branch is open on that date, push to array
      if(h.day == day) {
        if (i == 0) {
        days.push(
          {
          label: date.format("dddd Do MMMM").toString() + " (Today)",
          value: date.format("dddd Do MMMM").toString(),
          }
        )
      }         else if (i == 1) {
              days.push(
                {
                label: date.format("dddd Do MMMM").toString() + " (Tomorrow)",
                value: date.format("dddd Do MMMM").toString(),
                }
              )
            }else {
        days.push(
          {
          label: date.format("dddd Do MMMM").toString(),
          value: date.format("dddd Do MMMM").toString(),
          }
        )
      }
      }
    })

  }
  return days

}

function getSlotIntervals(hoursData, selectDate, prepareTime, deliveryTime, cartType) {

  var result = [];

  // If date is selected show time array.
  if (selectDate != null && selectDate != "Select Date") {

    // This formts time back for moment
    const selectedDate = moment(selectDate, "dddd Do MMMM")
    const selectedDay = selectedDate.format("d")

    // Gets current time
    const currentDate = moment(new Date(), "dddd Do MMMM")
    const currentDay = currentDate.format("d")
    const currentTime = moment(currentDate, 'HHmm')

    // This finds the approciate day from database
    const day = hoursData.filter(d => d.day == selectedDay && d.branch == global.branchId)

    var start = moment(day[0].opening, 'HHmm').add(prepareTime, 'minutes')

    if (cartType == 1) {
      start = start.add(deliveryTime, 'minutes')
    }

    var end = moment(day[0].closing, 'HHmm');

    // If Date is Today's Date and currently open, make Opening date now and add prep time
    if (selectedDate.format("dddd Do MMMM") == currentDate.format("dddd Do MMMM")) {
      if (moment(day[0].opening, 'HHmm') < currentTime) {
        // If currently open
        start = moment(currentDate).add(prepareTime, 'minutes')

        if (cartType == 1) {
          start = start.add(deliveryTime, 'minutes')
        }
      }
    }

    // Round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js hand les that correctly
    start.minutes(Math.ceil(start.minutes() / 5) * 5);

    var current = moment(start);

    if (selectedDay == currentDay && current <= end) {
      result.push({
        label: "ASAP",
        value: "ASAP"
      })
    }

    // Pushes Results in Array.
    while (current <= end) {
        result.push(
          {
            label: current.format('HH:mm').toString(),
            value: current.format('HH:mm').toString(),
          }
        )
        current.add(5, 'minutes');
    }

  }
  return result
}


class TimeModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
      selectedTime: null,
      days: [],
      times: [],
    }
  }

  componentDidMount() {

    const days = getDays(this.props.maxPreOrderDays, this.props.hoursData)

    // Gets the branches next available days
    this.setState(state => ({
      days: days,
    }))
  }

  selectDate = (date) => {
    this.setState(state => ({
      selectedDate: date,
      selectedTime: null,
    }))
  }

  selectTime = (time) => {
    this.setState(state => ({
      selectedTime: time,
    }))
  }

  renderTimeModal = (isVisible, toggleTimeModal, cartData, prepareTime, deliveryTime) => {

    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>
        <View style={styles.header}>

              <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>{cartData.type == 2 ? "Collection" : cartData.type == 1 ? "Delivery" : null} Time</Text>
              <TouchableOpacity
                onPress={() => toggleTimeModal()}
                style={styles.headerClose}>
                <Icon name="x" size={25} color={colors.textSecondary}/>
            </TouchableOpacity>
            </View>

            <View style={{ }}>

            <Text style={{fontSize: 13, fontFamily: fonts.bold, marginTop: 20, marginBottom: 0}}>Choose Date</Text>
              <RNPickerSelect
                value={this.state.selectedDate}
                doneText="Select"
                placeholder={{
                  label: "Select Date"
                }}
                onValueChange={(value) => this.selectDate(value)}
                items={this.state.days}
                textInputProps={{color: colors.textSecondary}}
                style={{...pickerSelectStyles}}
                Icon={() => {
                  if (Platform.OS != "web") {
                  return <Icon name="ios-arrow-down" size={15} color="gray" />;
                }else {
                  return null
                }
                }}
              />

            <Text style={{fontSize: 13, fontFamily: fonts.bold, marginTop: 20, marginBottom: 0}} adjustsFontSizeToFit allowFontScaling>Choose Time</Text>
              <RNPickerSelect
                placeholder={{
                  label: "Select Time Slot"
                }}
                doneText="Select"
                value={this.state.selectedTime}
                onValueChange={(value) => this.selectTime(value)}
                items={getSlotIntervals(this.props.hoursData, this.state.selectedDate, this.props.prepareTime, this.props.deliveryTime, this.props.cartData.type)}
                textInputProps={{color: colors.textSecondary}}
                style={{...pickerSelectStyles}}
                textInputProps={{color: colors.textSecondary}}
                Icon={() => {
                  if (Platform.OS != "web") {
                  return <Icon name="ios-arrow-down" size={15} color="gray" />;
                }else {
                  return null
                }
                }}
              />

            <AddTimeButton
              selectedTime={this.state.selectedTime}
              selectedDate={this.state.selectedDate}
              toggleTimeModal={() => toggleTimeModal()}
              cartData={cartData}
            />
            </View>
        </View>
      </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderTimeModal(this.props.isVisible, this.props.toggleTimeModal, this.props.cartData, this.props.isPreOrder, this.props.prepareTime)}</View>
    )
  }
}

export default TimeModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
    fontFamily: fonts.regular
  },
  modal: {
    minHeight: 350,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    padding: 30
  },
  modalWeb: {
    minHeight: 350,
    backgroundColor:colors.white,
    minWidth: 600,
        alignSelf: "center",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    padding: 30
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 22,
    fontFamily: fonts.bold
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  orderTypeIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    marginTop: 5,
    marginRight: 15,
    alignContent: "flex-end"
  },
  orderTypeText: {
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontFamily: fonts.regular
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.border,
    color: colors.text,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputWeb: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.border,
    color: colors.text,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.border,
    color: colors.text,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  iconContainer: {
    top: 30,
    right: 20,
  },
});
